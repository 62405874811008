import React from "react"
import PropTypes from "prop-types"

import CarteTitreSeul from "./CarteTitreSeul"

export default function ListeCartesTitreSeul({ pages, id }) {
  return (
    <nav className="liste-cartes" id={id}>
      {pages.map(c => {
        return <CarteTitreSeul key={c.code} page={c}></CarteTitreSeul>
      })}
    </nav>
  )
}

ListeCartesTitreSeul.propTypes = {
  pages: PropTypes.array,
  id: PropTypes.string,
}
