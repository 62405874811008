import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BoutonContinuer from "../../../composants/boutons/BoutonContinuer"
import Image from "../../../composants/image/Image"
import LayoutEN from "../../../composants/layout/en/LayoutEN"
import ListeCartesTitreSeul from "../../../composants/navigation/ListeCartesTitreSeul"
import Lien from "../../../composants/navigation/Lien"
import Section from "../../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirPages,
} from "../../../js/client-es/utils"

import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageBoutique: allPagesCompilationYaml(
      filter: { code: { eq: "boutiqueEN" } }
    ) {
      ...FragmentPageYaml
    }
    imageAstrophotographieAvecUnReflex: allImagesCompilationYaml(
      filter: { code: { in: ["astrophotographieavecUnReflex"] } }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesBoutique: allImagesCompilationYaml(
      filter: { code: { in: ["boutiqueMiseEnScene"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    galeriesVenteTirages: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "venteTiragesVoieLacteeEN"
            "venteTiragesNebuleusesEN"
            "venteTiragesAuroresBorealesEN"
            "venteTiragesGalaxiesEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersVenteTirages
    }
  }
`

export default function Boutique() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageBoutique")
  const images = obtenirImages(
    resultatsRequete,
    "imagesBoutique",
    "imagesPortraitMoyen",
    "imageAstrophotographieAvecUnReflex"
  )
  const galeriesVenteTirages = obtenirPages(
    resultatsRequete,
    "galeriesVenteTirages"
  )

  return (
    <LayoutEN page={page}>
      <Section titre="In French : Astrophotography with a DSLR (or an hybrid)">
        <p>
          To help you start or progress in astrophotography with your DSLR or
          your hybrid, an eBook of <strong>213 pages</strong> of techniques from
          more than 15 years of practice in the discipline.
        </p>
        <p>
          <strong>In summary:</strong>
        </p>
        <ul>
          <li>
            For each topic, field experiences and a detailed tutorial processing
            with Photoshop.
          </li>
          <li>Milky Way and arches of Milky Way.</li>
          <li>Northern lights.</li>
          <li>Stars showers.</li>
          <li>
            Deep-sky (nebulae, galaxies…) <strong>(43 pages)</strong>.
          </li>
          <li>Moon and comets.</li>
          <li>Virtual tours.</li>
          <li>Print your astrophotographs.</li>
          <li>…</li>
        </ul>
        <div
          id="boutique-conteneur-astrophotographie-reflex"
          className="conteneur-cote-a-cote conteneur-sans-marge"
        >
          <Lien
            codePageCible="astrophotographieReflex"
            classeCSS="lien-sans-effet"
          >
            <Image
              image={obtenirImage(images, "astrophotographieavecUnReflex")}
              afficherLegende={false}
              afficherOmbre={false}
              langue={page.langue}
            />
          </Lien>
          <div>
            <BoutonContinuer
              texte="More informations"
              codePageCible="astrophotographieReflex"
              classeCSS="bouton bouton-continuer"
            />
          </div>
        </div>
      </Section>
      <Section titre="Astronomy art prints">
        <p>
          Pigment art prints, guaranteeing excellent durability,{" "}
          <strong>of my most beautiful astrophotographs</strong>. Some have been
          recognized internationally.
        </p>
        <p>
          The prints are <strong>handcrafted</strong> and come with a
          certificate of authenticity signed by the author. They are numbered
          and may only be reproduced in a maximum of 30 copies whatever their
          formats. Certainly <strong>an ideal gift</strong> for all space
          lovers.
        </p>
        <Image
          image={obtenirImage(images, "boutiqueMiseEnScene")}
          afficherLegende={false}
          afficherOmbre={false}
          langue={page.langue}
        />
        <h3>Collections</h3>
        <ListeCartesTitreSeul pages={galeriesVenteTirages} id="galeries-nav" />
      </Section>
    </LayoutEN>
  )
}
